import Home from "../pages/home";
import Link from "../pages/link";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/link",
    element: <Link />,
  },
  {
    path: "*",
    element: <Home />,
  },
];
export default routes;
